export default class BasePlugin {
  constructor (context) {
    this.context = context
  }

  send (payload = {}) {
    return this.context.$http.post(`${this.context.$config.api}/registryservice/plugins/execute`,
      {
        name: this.context.pluginName, payload: payload
      }
    )
  }

  openExportAttachment (arrayBufferedData, me, fileExtension, filename) {
    let mimeType = null
    switch (fileExtension) {
      case 'xlsx':
        mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        break
      case 'xlsm':
        mimeType = 'application/vnd.ms-excel.sheet.macroEnabled.12'
        break
      case 'pptx':
        mimeType = 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
        break
      default:
        console.log(fileExtension)
        me.context.$msgbox({
          type: 'info',
          message: 'Неизвестная ошибка'
        })
        return
    }
    let blob = new Blob([arrayBufferedData], {type: mimeType})
    let url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    if (filename !== undefined) {
      a.download = filename
    }
    a.click()
  }
}
